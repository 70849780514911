import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import queryString from 'query-string';
import { loginRequest } from '../msal/authConfig';

const sessionStorageKey = "florabank_signup_status";

const SignUp = ({ location, history }: any) => {
    const isAuthenticated = useIsAuthenticated();
    const { accounts, instance, inProgress  } = useMsal();

    console.log("Got location as:", location.pathname, accounts, inProgress);

    const qryParams = queryString.parse(location.search);
    let content = "Please wait while we check your credentials...";

    if(qryParams.error) {
        console.log("Got error!");
        //http://localhost:3000/signin?error=access_denied&error_description=AADB2C90091%3a+The+user+has+cancelled+entering+self-asserted+information.%0d%0aCorrelation+ID%3a+56a2dffc-7322-4b10-af5d-2224a3a22588%0d%0aTimestamp%3a+2021-07-18+11%3a22%3a02Z%0d%0a
        content = qryParams.error_description ? qryParams.error_description.toString() : "An error occurred while performing signup, please try again after some time. ";
        content += "Please wait while we redirect you to home page...";
    }

    useEffect(() => {

        console.log("========> Running SignUp useEffect");

        const refreshObj = async () => {
            const qryParams = queryString.parse(location.search);

            if(qryParams.error) {
                await setTimeout( async () => {          
                    navigate('/');
                }, 2222);
            }
            else 
            {
                if (isAuthenticated) {
                    sessionStorage.setItem(sessionStorageKey, "true");
                    navigate('/userprofile/');
                }
                else {
                  await setTimeout( async () => {
                    let status = sessionStorage.getItem(sessionStorageKey);
                    if(!status || status !== "true") {
                      await instance.loginRedirect();
                    }
                  }, 3333);
                }
            }            
        }

        refreshObj();
    }, [accounts, inProgress, instance]);
    
    return <>{content}</>;
}

export default SignUp;